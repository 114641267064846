
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import store from "../../store";
import { LoadEntity } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import { onUpdated } from "@vue/runtime-core";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import { ADJUDICATE_STATUS, ADJUDICATE_TYPE } from "@/core/config/Constant";
import Froala from "@/components/base/editor/Froala.vue";
import { Field } from "vee-validate";
import { AdjudicationLine } from "@/core/entity/AdjudicationLine";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import InvoiceService from "@/core/services/InvoiceService";
import router from "@/router";

export default defineComponent({
  name: "InvoiceAdjudicate",
  components: {BaseSelect, Froala, BaseRadio, BaseForm, Field},
  setup() {
    const submitting = ref(false);
    const locking = ref(false);
    const model = ref({status: 'TO_ADJUDICATE', lines: new Array<AdjudicationLine>(), note: '', eob: ''});
    const invoice = computed(() => store.state.InvoiceModule.invoice);
    const total = ref(0);
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Adjudicate Invoice', [
        {link: true, router: '/invoices/' + invoice?.value?.id, text: 'View Invoice'},
        {link: false, router: '', text: 'Adjudicate Invoice'}
      ])
    })

    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Adjudicate  Invoice', [
        {link: true, router: '/invoices/' + invoice?.value?.id, text: 'View Invoice'},
        {link: false, router: '', text: 'Adjudicate Invoice'}
      ])
    })

    const onTypeSelect = (type) => {
      console.log("type is", type);
      if (type === 'NONE_PAYABLE' || type === 'INELIGIBLE') {
        total.value = 0;
        locking.value = true;
      } else {
        calculatePayable()
        locking.value = false;
      }
    }

    const calculatePayable = () => {
      total.value = invoice.value.repriced - model?.value?.lines.map(item => Number(item.amount)).reduce((prev, curr) => prev + curr, 0);
    }
    const calculateItemAmount = (line, index) => {
      line.amount = Number((invoice.value.repriced * line.percent / 100).toFixed(2));
      model.value.lines[index] = line;
      calculatePayable();
    }

    const buildAdjudicate = (invoice) => {
      total.value = invoice.repriced;
      if (invoice.adjudication) {
        model.value.status = invoice.adjudication.status
        model.value.lines = invoice.adjudication.lines
        model.value.note = invoice.adjudication.note
        model.value.eob = invoice.adjudication.eob
      } else {
        model.value = {status: 'TO_ADJUDICATE', lines: new Array<AdjudicationLine>(), note: '', eob: ''}
        model.value.lines.push({type: 'INELIGIBLE', percent: 0, amount: 0});
      }
      calculatePayable();
    }

    buildAdjudicate(invoice.value);
    watch(invoice, (cb) => {
      buildAdjudicate(cb);
    })
    const addLine = () => {
      model.value.lines.push(new AdjudicationLine())
    }

    const removeLine = (line) => {
      if (model.value?.lines) model.value.lines = model?.value?.lines.filter(item => item !== line)
    }
    const validate = {};

    const submit = () => {
      submitting.value = true
      InvoiceService.adjudicate(invoice.value.id, model.value).then(res => {
        router.push({
          name: 'invoiceOverview',
          params: {id: res.id}
        })
      }).finally(() => {
        submitting.value = false;
      })
    }
    return {
      ...LoadEntity(Actions.LOAD_INVOICE),
      invoice,
      locking,
      validate,
      ADJUDICATE_STATUS,
      model,
      total,
      calculatePayable,
      addLine,
      removeLine,
      ADJUDICATE_TYPE,
      submitting,
      submit,
      onTypeSelect,
      calculateItemAmount,
    }
  },
  methods: {
    onCancel() {
      this.$router.go(-1)
    }
  }
})
