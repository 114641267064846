export interface IAdjudicationLine {
  type: 'INELIGIBLE' | 'DEDUCTIBLE' | 'COINSURANCE' | 'COPAY' | 'OTHER_INSURANCE' | 'CLIENT_RESPONSIBILITY' | 'CLIENT_PAID';
  percent: number;
  amount: number;
}

export class AdjudicationLine implements IAdjudicationLine {
  type: 'INELIGIBLE' | 'DEDUCTIBLE' | 'COINSURANCE' | 'COPAY' | 'OTHER_INSURANCE' | 'CLIENT_RESPONSIBILITY' | 'CLIENT_PAID' = 'INELIGIBLE';
  percent: number = 0;
  amount: number = 0;
}